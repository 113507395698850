// Social Media Section

.social-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #094603;
  padding: 10px 0;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: top 0.3s ease-in-out;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.contact-info {
  margin-left: 20px;
  font-size: 1rem;
}

.phone {
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
}

.social-icons {
  display: flex;
  flex-wrap: wrap;  // Allow icons to wrap on smaller screens
  gap: 15px;
  margin-right: 20px;
}

.social-icons a {
  color: #ffffff;
  font-size: 1.3rem;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #009688; /* Hover effect */
}

// Header Section
.header-container {
  position: fixed;
  top: 49px; /* Adjust to keep below social section */
  left: 0;
  width: 100%;
  background-color: #449f3b;
  z-index: 10000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
  .logo{
    max-height: 60px;
    img{
    max-height: 60px;
    width: 70%;
    }
  }
}

.header-container.scrolled {
  top: 0;
  background-color: #449f3b;
  .logo{
    max-height: 60px;
    img{
    max-height: 60px;
    width: 70%;
    }
  }
  .navbar a {
    color: #ffffff;
  }
  .navbar a:hover {
    color: gold;
  }
  .navbar a.active {
    color: gold; /* Highlight active link */
    border-bottom: 2px solid gold; /* Optional underline for active link */
  }
  .navbar.open{
    background-color: #094603;
  }
}

.navbar {
  display: flex;
  gap: 20px;
}

.navbar a {
  text-decoration: none;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  transition: color 0.3s ease;
}

.navbar a.active {
  color: gold; /* Highlight active link */
  border-bottom: 2px solid gold; /* Optional underline for active link */
}


.navbar a:hover {
  color: gold;
}

// Mobile Menu Icon
.menu-icon {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  height: 20px;
  width: 30px;
  cursor: pointer;

  .bar {
    height: 4px;
    background-color: #fff;
    transition: transform 0.3s ease;
  }

  &.open .bar:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  &.open .bar:nth-child(2) {
    opacity: 0;
  }

  &.open .bar:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }
}

// For responsive design
@media (max-width: 800px) {
  body {
    padding-top: 0 !important;
}
  .header-container{
    top: 0px;
    h1{
      font-size: 1.5rem;
      color:#094603;
      margin: 0%;
    }
  }
  .header-container.scrolled {
    top: 0;
    h1{
      color: #ffffff;
    }
   .menu-icon .bar{
    background-color: #ffffff;
   }
  }
  .navbar {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;
    padding: 10px;
    background-color: #094603;
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 9999;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .navbar.open {
    display: flex;
  }

  .menu-icon {
    display: flex;
  }

  .social-container {
   display: none;
  }

  .social-icons {
    justify-content: center;
  }
}

// Ensures content is not hidden behind fixed header
body {
  padding-top: 45px; // Adjust for social media section
}

