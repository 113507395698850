.scroll-to-top {
    position: fixed;
    bottom: 20px;  // Adjust for spacing
    right: 20px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1000;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    img{
        width: 2.5rem;
    }
  
    // Animation to slide in/out
    opacity: 1;
    transform: translateY(0);
  }
  
  .scroll-to-top-hidden {
    opacity: 0;
    transform: translateY(20px); // Slide down when hidden
    pointer-events: none;
  }
  .whatsapp-icon {
    position: fixed;
    right: 0rem;
    top: 50%;
    z-index: 99999;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    img{
        width: 3rem;
    }
  }
  
  @media screen and (max-width:780px) {
    .scroll-to-top img {
        width: 1.5rem;
    }    
  }