.footer-wrapper {
  .footer-extra {
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 20px;
    background-color: #165710;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;

    .menu-list{
      a{
        color:#fff ;
        text-decoration: none;
      }
      a:hover{
        color: #007bff;
      }
    }

    .socialicons {
      display: flex;
      flex-direction: column;
      .Footer-social-icons{
        display: flex;
        gap: 15px;
        img{
          width: 2rem;
        }
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      padding: 15px;

      .socialicons {
        justify-content: center;
      }

      .menu-list, .contact-info, .policy-links, .socialicons {
        margin-bottom: 15px;
      }
    }

    .menu-list, .contact-info, .policy-links, .socialicons {
      flex: 1;
      margin: 0 10px;
      max-width: max-content;

      h3 {
        font-weight: 600;
        font-size: 1.2rem;
        color:gold;
        margin-bottom: 10px;
      }

      ul, p {
        font-size: 1rem;
        color: #fff;
      }

      ul {
        list-style: none;
        padding: 0;
      }

      li, p {
        margin: 8px 0;
        cursor: pointer;
        transition: color 0.2s ease;

        &:hover {
          color: #007bff;
        }
      }
    }
  }

  .footer {
    text-align: center;
    padding: 15px;
    background-color: #094603;
    color: #f4f5f7;
    font-size: 0.9rem;
    a{
      color:antiquewhite;
    }
  }

  .popup-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 20px;

    .popup-content {
      background-color: #ffffff;
      padding: 30px;
      border-radius: 10px;
      width: 100%;
      max-width: 600px;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
      position: relative;
      animation: fadeIn 0.3s ease;

      h2 {
        font-size: 1.6rem;
        color: #333;
        margin-top: 0;
      }

      p {
        font-size: 1rem;
        color: #555;
        line-height: 1.6;
        margin-bottom: 0;
      }

      .close-button {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        font-size: 1.4rem;
        color: #888;
        transition: color 0.2s ease;

        &:hover {
          color: #333;
        }
      }
    }

    @keyframes fadeIn {
      from { opacity: 0; transform: scale(0.95); }
      to { opacity: 1; transform: scale(1); }
    }
  }
}
